import { App } from 'eos-base-app/src/App';
import React, { lazy } from 'react';
import { Route } from 'react-router-dom';
import { AppProps } from './config/AppProps';

const News = lazy(() => import('eos-base-app/src/Controllers/News/List'));
const NewsDetails = lazy(() => import('eos-base-app/src/Controllers/News/Details'));
const NewsDocs = lazy(() => import('eos-base-app/src/Controllers/News/Documents'));
const BlockList = lazy(() => import('./controllers/blockdays/List'));
const BlockVehicle = lazy(() => import('./controllers/blockdays/VehicleOverview'));
const Contacts = lazy(() => import('./controllers/carrier/Contacts'));
const Driver = lazy(() => import('./controllers/carrier/Driver'));
const Drivers = lazy(() => import('./controllers/carrier/Drivers'));
const Feedback = lazy(() => import('./controllers/carrier/Feedback'));
const MyData = lazy(() => import('./controllers/carrier/MyData'));
const Trailer = lazy(() => import('./controllers/carrier/Trailer'));
const Trailers = lazy(() => import('./controllers/carrier/Trailers'));
const Vehicle = lazy(() => import('./controllers/carrier/Vehicle'));
const Vehicles = lazy(() => import('./controllers/carrier/Vehicles'));
const Contracts = lazy(() => import('./controllers/contracts/List'));
const IncReports = lazy(() => import('./controllers/increport/IncReports'));
const Invoicing = lazy(() => import('./controllers/invoicing/List'));
const Manuals = lazy(() => import('./controllers/manuals/List'));
const Settings = lazy(() => import('./controllers/settings/List'));
const ToursMonitor = lazy(() => import('./controllers/tours/ToursMonitor'));
const Addresses = lazy(() => import('./controllers/apv/Addresses'));
const AddressView = lazy(() => import('./controllers/apv/DetailsAddress/View'));
// <Route exact path='/tour/:id?' component={TourDetails} />

export class Routes extends React.PureComponent {
    /**
     * Render component 
     * @returns {JSX.Element} Component layout
     */
    public render(): JSX.Element {
        return (
            <App {...AppProps}>
                <Route exact path={['/', '/news']} component={News} />
                <Route exact path={['/news', '/news/:id']} component={NewsDetails} />
                <Route exact path='/carrier/contacts' component={Contacts} />
                <Route exact path='/carrier/mydata' component={MyData} />
                <Route exact path='/carrier/drivers' component={Drivers} />
                <Route exact path='/carrier/drivers/:id?' component={Driver} />
                <Route exact path='/carrier/vehicles' component={Vehicles} />
                <Route exact path='/carrier/vehicles/:id?' component={Vehicle} />
                <Route exact path='/carrier/trailers' component={Trailers} />
                <Route exact path='/carrier/trailers/:id?' component={Trailer} />
                <Route exact path='/carrier/feedback' component={Feedback} />
                <Route exact path='/contracts' component={Contracts} />
                <Route exact path='/invoicing' component={Invoicing} />
                <Route exact path='/blockdays' component={BlockList} />
                <Route exact path='/blockdays/:id?' component={BlockVehicle} />
                <Route exact path={['/increports', '/increports/:id']} component={IncReports} />
                <Route exact path={['/tourmonitor', '/tourmonitor/:id']} component={ToursMonitor} />
                <Route exact path='/manuals' component={Manuals} />
                <Route exact path='/newsdocs' component={NewsDocs} />
                <Route exact path='/settings' component={Settings} />
                <Route exact path={['/apv/addresses']} component={Addresses} />
                <Route exact path={['/apv','/apv/:id']} component={AddressView} />
            </App>
        );
    }
}
