import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faBuilding } from '@fortawesome/pro-regular-svg-icons/faBuilding'
import { faEnvelopeOpen } from '@fortawesome/pro-regular-svg-icons/faEnvelopeOpen'
import { faEnvelope } from '@fortawesome/pro-regular-svg-icons/faEnvelope'
import { faPhone } from '@fortawesome/pro-regular-svg-icons/faPhone'
import { faBalanceScaleLeft } from '@fortawesome/pro-regular-svg-icons/faBalanceScaleLeft'
import { faFire } from '@fortawesome/pro-regular-svg-icons/faFire'
import { faGavel } from '@fortawesome/pro-regular-svg-icons/faGavel'
import { faClipboardList } from '@fortawesome/pro-regular-svg-icons/faClipboardList'
import { faUserTie } from '@fortawesome/pro-regular-svg-icons/faUserTie'
import { faHandHeart } from '@fortawesome/pro-regular-svg-icons/faHandHeart'
import { faTruckMoving } from '@fortawesome/pro-regular-svg-icons/faTruckMoving'
import { faTruck } from '@fortawesome/pro-regular-svg-icons/faTruck'
import { faSearchPlus } from '@fortawesome/pro-regular-svg-icons/faSearchPlus'
import { faListAlt } from '@fortawesome/pro-regular-svg-icons/faListAlt'
import { faCalendarCheck } from '@fortawesome/pro-regular-svg-icons/faCalendarCheck'
import { faCalendarAlt } from '@fortawesome/pro-regular-svg-icons/faCalendarAlt'
import { faUserAlt } from '@fortawesome/pro-regular-svg-icons/faUserAlt'
import { faFax } from '@fortawesome/pro-regular-svg-icons/faFax'
import { faAddressCard } from '@fortawesome/pro-regular-svg-icons/faAddressCard'
import { faAddressBook } from '@fortawesome/pro-regular-svg-icons/faAddressBook'
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons/faArrowLeft'
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons/faArrowRight'
import { faArrowToLeft } from '@fortawesome/pro-regular-svg-icons/faArrowToLeft'
import { faArrowToRight } from '@fortawesome/pro-regular-svg-icons/faArrowToRight'
import { faTruckContainer } from '@fortawesome/pro-regular-svg-icons/faTruckContainer'
import { faWeightHanging } from '@fortawesome/pro-regular-svg-icons/faWeightHanging'
import { faForklift } from '@fortawesome/pro-regular-svg-icons/faForklift'
import { faCommentAlt } from '@fortawesome/pro-regular-svg-icons/faCommentAlt'
import { faAsterisk } from '@fortawesome/pro-regular-svg-icons/faAsterisk'
import { faHandHoldingBox } from '@fortawesome/pro-regular-svg-icons/faHandHoldingBox'
import { faLink } from '@fortawesome/pro-regular-svg-icons/faLink'
import { faExclamationCircle } from '@fortawesome/pro-regular-svg-icons/faExclamationCircle'
import { faExclamationSquare } from '@fortawesome/pro-regular-svg-icons/faExclamationSquare'
import { faClipboard } from '@fortawesome/pro-regular-svg-icons/faClipboard'
import { faStamp } from '@fortawesome/pro-regular-svg-icons/faStamp'
import { faAmbulance } from '@fortawesome/pro-regular-svg-icons/faAmbulance'
import { faFileInvoiceDollar } from '@fortawesome/pro-regular-svg-icons/faFileInvoiceDollar'
import { faArrowAltUp } from '@fortawesome/pro-regular-svg-icons/faArrowAltUp'
import { faArrowAltDown } from '@fortawesome/pro-regular-svg-icons/faArrowAltDown'
import { faDownload } from '@fortawesome/pro-regular-svg-icons/faDownload'
import { faQuestionCircle } from '@fortawesome/pro-regular-svg-icons/faQuestionCircle'
import { faFilePdf } from '@fortawesome/pro-regular-svg-icons/faFilePdf'
import { faFileContract } from '@fortawesome/pro-regular-svg-icons/faFileContract'
import { faChalkboardTeacher } from '@fortawesome/pro-regular-svg-icons/faChalkboardTeacher'
import { faTrafficLightStop } from '@fortawesome/pro-regular-svg-icons/faTrafficLightStop'
import { faPlus } from '@fortawesome/pro-regular-svg-icons/faPlus'
import { faUsersCog } from '@fortawesome/pro-regular-svg-icons/faUsersCog'
import { faFolderOpen } from '@fortawesome/pro-regular-svg-icons/faFolderOpen'
import { faLightbulb } from '@fortawesome/pro-regular-svg-icons/faLightbulb'
import { faCarCrash } from '@fortawesome/pro-regular-svg-icons/faCarCrash'
import { faEnvelopeOpenText } from '@fortawesome/pro-regular-svg-icons/faEnvelopeOpenText'
import { faWarehouse } from '@fortawesome/pro-regular-svg-icons/faWarehouse'
import { faDolly } from '@fortawesome/pro-regular-svg-icons/faDolly'
import { faHandReceiving } from '@fortawesome/pro-regular-svg-icons/faHandReceiving'
import { faMapMarker } from '@fortawesome/pro-regular-svg-icons/faMapMarker'
import { faPaperclip } from '@fortawesome/pro-regular-svg-icons/faPaperclip'
import { faPencilAlt } from '@fortawesome/pro-regular-svg-icons/faPencilAlt'
import { faBookReader } from '@fortawesome/pro-regular-svg-icons/faBookReader'
import { faHandshake } from '@fortawesome/pro-regular-svg-icons/faHandshake'
import { faExpandAlt } from '@fortawesome/pro-regular-svg-icons/faExpandAlt'
import { faCompressAlt } from '@fortawesome/pro-regular-svg-icons/faCompressAlt'
import { faStopwatch } from '@fortawesome/pro-regular-svg-icons/faStopwatch'
import { faCloudUpload } from '@fortawesome/pro-regular-svg-icons/faCloudUpload'
import { faClipboardCheck } from '@fortawesome/pro-regular-svg-icons/faClipboardCheck'
import { faSearch } from '@fortawesome/pro-regular-svg-icons/faSearch'
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons/faInfoCircle'
import { faRepeat } from '@fortawesome/pro-regular-svg-icons/faRepeat'
import { faCogs } from '@fortawesome/pro-regular-svg-icons/faCogs'
import { faSave } from '@fortawesome/pro-regular-svg-icons/faSave'
import { faDesktop } from '@fortawesome/pro-regular-svg-icons/faDesktop'
import { faFolderDownload } from '@fortawesome/pro-regular-svg-icons/faFolderDownload'
import { faFilePlus } from '@fortawesome/pro-regular-svg-icons/faFilePlus'
import { faMonitorHeartRate } from '@fortawesome/pro-regular-svg-icons/faMonitorHeartRate'
import { faListUl } from '@fortawesome/pro-regular-svg-icons/faListUl'
import { faParking } from '@fortawesome/pro-regular-svg-icons/faParking'
import { faKey } from '@fortawesome/pro-regular-svg-icons/faKey'
import { faCommentDots } from '@fortawesome/pro-regular-svg-icons/faCommentDots'

export const FaLibrary: IconDefinition[] = [
    faBuilding,
    faEnvelopeOpen,
    faEnvelope,
    faPhone,
    faBalanceScaleLeft,
    faFire,
    faGavel,
    faClipboardList,
    faUserTie,
    faHandHeart,
    faTruckMoving,
    faTruck,
    faSearchPlus,
    faListAlt,
    faCalendarCheck,
    faCalendarAlt,
    faUserAlt,
    faFax,
    faPhone,
    faAddressCard,
    faAddressBook,
    faArrowLeft,
    faArrowRight,
    faArrowToLeft,
    faArrowToRight,
    faTruckContainer,
    faWeightHanging,
    faForklift,
    faCommentAlt,
    faAsterisk,
    faHandHoldingBox,
    faLink,
    faExclamationCircle,
    faExclamationSquare,
    faClipboard,
    faStamp,
    faAmbulance,
    faFileInvoiceDollar,
    faArrowAltUp,
    faArrowAltDown,
    faDownload,
    faQuestionCircle,
    faFilePdf,
    faFileContract,
    faChalkboardTeacher,
    faTrafficLightStop,
    faPlus,
    faUsersCog,
    faFolderOpen,
    faLightbulb,
    faCarCrash,
    faEnvelopeOpenText,
    faWarehouse,
    faDolly,
    faHandReceiving,
    faMapMarker,
    faPaperclip,
    faPencilAlt,
    faBookReader,
    faHandshake,
    faExpandAlt,
    faCompressAlt,
    faStopwatch,
    faCloudUpload,
    faClipboardCheck,
    faSearch,
    faInfoCircle,
    faRepeat,
    faCogs,
    faSave,
    faDesktop,
    faFolderDownload,
    faFilePlus,
    faMonitorHeartRate,
    faListUl,
    faParking,
    faKey,
    faCommentDots
];